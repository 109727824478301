/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// Bootstrap select  for live search on select
require('../../node_modules/bootstrap-select/dist/js/bootstrap-select.js');
require('../../node_modules/bootstrap-select/dist/js/i18n/defaults-es_CL.js');
require('../../node_modules/bootstrap-select/dist/js/i18n/defaults-es_ES.js');
require('../../node_modules/bootstrap-select/dist/js/i18n/defaults-en_US.js');

// Bootstrap datepicker
require('./bootstrap-datepicker/bootstrap-datepicker.min.js');
require('./bootstrap-datepicker/bootstrap-datepicker.es.min.js');

//import { PDFDocument, StandardFonts, PDFNet } from 'pdf-lib';

$(document).ready(function () {
          // sidebar dashboard
          $('#sidebarCollapse').on('click', function () {
              $('#sidebar').toggleClass('active');
              $('#side-brand').toggleClass('active');
          });
        //para el select con buscador
        $('.selectpicker').selectpicker({
          noneResultsText:'Busqueda {0} sin resultados',
          noneSelectedText:'Sin Selección',
          multipleSeparator:', ',
          language: 'ES'
        });

        // Traduce texto de botón "close"->"cerrar" multiselect
        $('.bs-donebutton .btn-group button').text('Cerrar');

        //datepicker
         $('.datepicker input').datepicker({
             format: "dd-M-yyyy",
             todayBtn: "linked",
             language: "es",
             daysOfWeekHighlighted: "0,6",
             todayHighlight:true,
             autoclose: true
         });
         $('.datepicker-birthdate input').datepicker({
             format: "dd-mm-yyyy",
             todayBtn: "linked",
             language: "es",
             daysOfWeekHighlighted: "0,6",
             autoclose: true,
             startView: 3
         });

});

//for image uploads to s3
window.Vapor = require('laravel-vapor');


//prueba
//import $ from 'jquery';
//window.$ = window.jQuery = $;

//import 'jquery-ui/ui/widgets/datepicker.js';
//$('.datepicker').datepicker();
//window.Vue = require('vue');
//import Vue from 'vue';


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
/*
const app = new Vue({
    el: '#app',
});
*/
